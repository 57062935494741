<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <Patcher
          :itemValue="data.name"
          itemName="name"
          itemLabel="Name"
          :hideLabel="false"
          :route="patchRoute"
          inputElement="input"
          inputType="text"
          :previewValue="data.name"
          @emit="data.name = $event.value"
        ></Patcher>

        <Patcher
          :itemValue="data.infoText ? data.infoText : ''"
          itemName="infoText"
          itemLabel="Info-Text"
          :hideLabel="false"
          :route="patchRoute"
          inputElement="textarea-md"
          inputType=""
          :previewValue="data.infoText"
          @emit="data.infoText = $event.value"
        ></Patcher>

        <Patcher
          :itemValue="data.mediaUuid"
          itemName="mediaUuid"
          itemLabel="Bild"
          :hideLabel="false"
          :route="patchRoute"
          inputElement=""
          inputType="media"
          :previewValue="data.mediaUuid"
          @emit="data.mediaUuid = $event.value"
        ></Patcher>

        <Patcher
          :itemValue="data.isActive"
          itemName="isActive"
          itemLabel="Aktiv"
          :hideLabel="true"
          :route="patchRoute"
          inputElement="switch"
          inputType=""
          :selectOptions="statusItems"
          :previewValue="data.isActive"
          @emit="data.isActive = $event.value"
        ></Patcher>
      </v-col>

      <v-col v-if="data" cols="12">
        <v-card tile flat>
          <v-app-bar flat color="primary">
            <v-toolbar-title class="title white--text pl-0">
              Fragen
            </v-toolbar-title>
          </v-app-bar>

          <v-card-text>
            <table class="drag-table">
              <draggable
                v-model="data.questions"
                tag="tbody"
                ghost-class="ghost"
                :move="checkMove"
                @start="dragging = true"
                @end="handleDragEnd"
                handle=".draghandle"
              >
                <tr
                  v-for="question in data.questions"
                  :key="question.id"
                  class="is-draggable"
                >
                  <td class="draghandle" style="width: 32px">
                    <v-icon> mdi-drag </v-icon>
                  </td>

                  <td>
                    <span class="font-weight-bold">
                      {{ question.question }}
                    </span>
                    <br />
                    <span class="caption"> Typ: {{ question.type }} </span>
                    <br />
                    <span class="caption">
                      Feedback-Platzhalter: %%{{
                        question.feedbackPlaceholder
                      }}%%
                    </span>
                  </td>

                  <td style="width: 32px">
                    <v-btn
                      fab
                      x-small
                      color="primary"
                      elevation="0"
                      class="white--text"
                      @click="openQuestion(question)"
                    >
                      <v-icon dark> mdi-pencil </v-icon>
                    </v-btn>
                  </td>

                  <td style="width: 32px">
                    <v-btn
                      fab
                      x-small
                      color="red"
                      elevation="0"
                      class="white--text"
                      @click="askDelete(question.id)"
                    >
                      <v-icon dark> mdi-trash-can </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </draggable>
            </table>
          </v-card-text>

          <v-card-actions>
            <v-btn
              :loading="creatingActive"
              color="green"
              class="ma-2 white--text"
              depressed
              @click="addItem()"
            >
              Neue Frage
              <v-icon right dark>mdi-plus</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col v-if="data" cols="12">
        <v-card tile flat>
          <v-app-bar flat color="primary">
            <v-toolbar-title class="title white--text pl-0">
              Set-Bedingungen
            </v-toolbar-title>
          </v-app-bar>

          <v-card-text>
            <v-row>
              <v-col cols="auto">
                <p>Set-Bedingungen Info</p>
                <v-select
                  v-model="ifQuestionId"
                  :items="availableQuestions"
                  label="Frage"
                  item-text="question"
                  item-value="uuid"
                  solo
                ></v-select>
              </v-col>

              <v-col cols="auto">
                <p>Set-Bedingungen Antwortinfo</p>
                <v-select
                  v-model="ifAnswerId"
                  :items="availableAnswers"
                  label="Antwort"
                  item-text="answer"
                  item-value="uuid"
                  solo
                ></v-select>
              </v-col>

              <v-col cols="auto">
                <v-btn
                  depressed
                  color="green"
                  class="white--text my-1"
                  v-if="ifQuestionId && ifAnswerId"
                  @click="updateSetConditions()"
                >
                  Übernehmen
                </v-btn>
                <br />
                <v-btn
                  depressed
                  color="red"
                  class="white--text my-1"
                  v-if="ifQuestionId && ifAnswerId"
                  @click="removeSetConditions()"
                >
                  Löschen
                </v-btn>
              </v-col>
            </v-row>

            If Question {{ ifQuestionId }} has Answer {{ ifAnswerId }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="questionDialog"
      max-width="1500px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="questionDialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
          <v-toolbar-title> Frage </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <QuestionDetails
            :data="questionData"
            :obaId="obaId"
            :setId="data.id"
            :key="componentKey"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <AreYouSureDialog
      title="Diese Frage löschen?"
      text="Möchtest Du diese Frage tatsächlich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden."
      :dialog="safeguardingDialog"
      @dialogEmit="safeguarding"
    />
  </v-container>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import draggable from "vuedraggable";
import QuestionDetails from "@/components/page-plugins/oba/QuestionDetails";
import Patcher from "@/components/ui/Patcher";
import AreYouSureDialog from "@/components/ui/AreYouSureDialog";

export default {
  name: "SetDetails",

  components: {
    draggable,
    QuestionDetails,
    Patcher,
    AreYouSureDialog,
  },

  data() {
    return {
      safeguardingDialog: false,
      ifQuestionId: null,
      ifAnswerId: null,
      fetchinData: false, // should be true when fetching data
      creatingActive: false,
      questionDialog: false,
      questionData: [],
      availableQuestions: [],
      availableAnswers: [],
      componentKey: 0,
      itemToDelete: null,
      patchRoute: `/obas/${this.obaId}/sets/${this.data.id}`,
      statusItems: [
        {
          name: "Ja",
          value: "1",
        },
        {
          name: "Nein",
          value: "0",
        },
      ],
    };
  },

  props: ["data", "obaId"],

  created: function () {
    this.getAllQuestionsOfDoc();
  },

  mounted() {
    this.ifAnswerId = this.data.ifAnswerId;
    this.ifQuestionId = this.data.ifQuestionId;
  },

  watch: {
    // whenever question changes, this function will run
    ifQuestionId: function () {
      this.availableQuestions.forEach((question) => {
        if (question.uuid === this.ifQuestionId) {
          this.availableAnswers = question.answers;
        }
      });
    },
  },

  methods: {
    safeguarding(value) {
      this.safeguardingDialog = false;

      if (value) {
        this.deleteItem();
      }
    },

    async deleteItem() {
      const url = `${this.patchRoute}/questions/${this.itemToDelete}`;
      let response = await this.deleteRequest(url); // await data from mixin

      if (response.status === 200) {
        let indexToDelete = null;

        this.data.questions.forEach((el, index) => {
          if (el.id === this.itemToDelete) {
            indexToDelete = index;
          }
        });

        this.data.questions.splice(indexToDelete, 1);

        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Der Datensatz wurde gelöscht";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    askDelete(value) {
      this.itemToDelete = value;
      this.safeguardingDialog = true;
    },

    hasQuestionSelected() {
      if (!this.ifQuestionId) {
        return true;
      }
    },

    async updateSetConditions() {
      const params = new URLSearchParams();
      params.append("ifQuestionId", this.ifQuestionId);
      params.append("ifAnswerId", this.ifAnswerId);

      this.preloader = true;
      let response = await this.patchRequest(`${this.patchRoute}`, params);

      if (response.status === 200) {
        // mutate
        this.data.ifQuestionId = this.ifQuestionId;
        this.data.ifAnswerId = this.ifAnswerId;

        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Datensatz wurde geupdated!";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }

      this.preloader = false;
    },

    async removeSetConditions() {
      const params = new URLSearchParams();
      params.append("ifQuestionId", null);
      params.append("ifAnswerId", null);

      this.preloader = true;
      let response = await this.patchRequest(`${this.patchRoute}`, params);
      this.preloader = false;

      if (response.status === 200) {
        // mutate
        this.data.ifQuestionId = null;
        this.data.ifAnswerId = null;

        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Datensatz wurde geupdated!";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }

      this.ifAnswerId = null;
      this.ifQuestionId = null;
    },

    async getAllQuestionsOfDoc() {
      this.fetchinData = true;
      let response = await this.getRequest(
        `obas/${this.obaId}/questions?excludeSet=${this.data.uuid}`
      ); // await data from mixin

      this.availableQuestions = response.data.data;

      if (this.ifAnswerId) {
        this.availableQuestions.forEach((question) => {
          if (question.uuid === this.data.ifQuestionId) {
            this.availableAnswers = question.answers;
          }
        });
      }
    },

    openQuestion(question) {
      this.questionData = question;
      this.componentKey += 1;
      this.questionDialog = true;
    },

    hasFeedback(value) {
      if (value) {
        return this.$t("answers.yes");
      } else {
        return this.$t("answers.no");
      }
    },

    async dragUpdate(data) {
      let response = await this.patchRequest(
        this.patchRoute + "/questions/sortOrder",
        JSON.stringify(data),
        "json"
      ); // await data from mixin

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Die Reihenfolge wurde erfolgreich geändert.";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async addItem() {
      let response = await this.postRequest(
        `obas/${this.obaId}/sets/${this.data.id}/questions`
      );

      let newItem = response.data.data;

      newItem.answers = []; // adding an empty answers array to avoid refetching
      this.data.questions.push(newItem);

      /* start snackbar */
      this.$root.snackbarGlobal.snackbar = true;
      this.$root.snackbarGlobal.snackbarText =
        "Die Frage wurde erfolgreich angelegt.";
      this.$root.snackbarGlobal.snackbarColor = "green";
      this.$root.snackbarGlobal.snackbarTimeout = 5000;
    },

    checkMove: function (e) {
      window.console.log("Future index: " + e.draggedContext.futureIndex);
    },

    handleMove(e) {
      /* SWAP-Hack: https://fir-vue-draggable.firebaseapp.com/swap/code */

      this.movingIndex = e.draggedContext.index;
      this.futureIndex = e.draggedContext.futureIndex;
      return false; // disable sort
    },

    handleDragEnd: function () {
      /* hier loope ich den mutierten array durch und bilde
      einen neuen array aus uuid und sortOrder.
      Dieses Array schicke ich dann via POST an die API */

      let idAndsortOrder = [];
      const newOrderedArray = this.data.questions;

      let sortOrder = 1;

      newOrderedArray.forEach((item) => {
        const set = {
          id: item.id,
          sortOrder: sortOrder,
        };

        idAndsortOrder.push(set);
        ++sortOrder;
      });

      this.dragUpdate(idAndsortOrder);
    },

    mutateData(value) {
      this.data[value.name] = value.value; // mutate
    },
  },

  computed: {
    //
  },

  mixins: [apiRequest, helpers],
};
</script>
