<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <Patcher
          :itemValue="data.answer"
          itemName="answer"
          itemLabel="Antwort"
          :hideLabel="false"
          :route="patchRoute"
          inputElement="input"
          inputType="text"
          :previewValue="data.answer"
          @emit="data.answer = $event.value"
        ></Patcher>

        <Patcher
          :itemValue="data.value"
          itemName="value"
          itemLabel="Wert"
          :hideLabel="false"
          :route="patchRoute"
          inputElement="input"
          inputType="text"
          :previewValue="data.value"
          @emit="data.value = $event.value"
        ></Patcher>

        <Patcher
          :itemValue="nullNotAllowed(data.feedback)"
          itemName="feedback"
          itemLabel="Feedback/Ergebnis"
          :hideLabel="false"
          :route="patchRoute"
          inputElement="textarea-md"
          inputType=""
          :previewValue="data.feedback"
          @emit="data.feedback = $event.value"
        ></Patcher>

        <Patcher
          :itemValue="data.mediaUuid"
          itemName="mediaUuid"
          itemLabel="Bild"
          :hideLabel="false"
          :route="patchRoute"
          inputElement=""
          inputType="media"
          :previewValue="data.mediaUuid"
          @emit="data.mediaUuid = $event.value"
        ></Patcher>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import Patcher from "@/components/ui/Patcher";
export default {
  name: "AnswerDetails",

  components: {
    Patcher,
  },

  data() {
    return {
      fetchinData: false, // should be true when fetching data
      creatingActive: false,
      answerDialog: false,
      answerData: [],
      componentKey: 0,
      patchRoute: `/obas/${this.obaId}/sets/${this.setId}/questions/${this.questionId}/answers/${this.data.id}`,
      typeItems: [
        {
          name: "Texteingabe",
          value: "text",
        },
        {
          name: "Datum",
          value: "date",
        },
        {
          name: "Auswahl",
          value: "select",
        },
        {
          name: "Checkboxen",
          value: "checkbox",
        },
        {
          name: "Radio",
          value: "radio",
        },
        {
          name: "Langer Text (Textarea)",
          value: "longtext",
        },
      ],
    };
  },

  props: ["data", "obaId", "setId", "questionId"],

  created: function() {
    //
  },

  methods: {
    nullNotAllowed(value) {
      if (value === null || value === "Null") {
        return "";
      } else {
        return value;
      }
    },

    disabledByPermission(value) {
      if (value !== "1" && this.masterPerm !== "1") {
        return true;
      } else {
        return false;
      }
    },

    mutateData(value) {
      this.data[value.name] = value.value; // mutate
    },
  },

  computed: {
    //
  },

  mixins: [apiRequest, helpers],
};
</script>
