<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <Patcher
          :itemValue="data.question"
          itemName="question"
          itemLabel="Frage"
          :hideLabel="false"
          :route="patchRoute"
          inputElement="input"
          inputType="text"
          :previewValue="data.question"
          @emit="data.question = $event.value"
        ></Patcher>

        <Patcher
          :itemValue="data.infotext"
          itemName="infotext"
          itemLabel="Detailierte Beschreibung"
          :hideLabel="false"
          :route="patchRoute"
          inputElement="textarea-md"
          inputType=""
          :previewValue="data.infotext"
          @emit="data.infotext = $event.value"
        ></Patcher>

        <Patcher
          :itemValue="data.type"
          itemName="type"
          itemLabel="Frage-Typ"
          :hideLabel="false"
          :route="patchRoute"
          inputElement="select"
          :selectOptions="typeItems"
          inputType=""
          itemValueKey="value"
          itemTextKey="name"
          :previewValue="data.type"
          @emit="data.type = $event.value"
        ></Patcher>

        <Patcher
          :itemValue="data.mediaUuid"
          itemName="mediaUuid"
          itemLabel="Bild"
          :hideLabel="false"
          :route="patchRoute"
          inputElement=""
          inputType="media"
          :previewValue="data.mediaUuid"
          @emit="data.mediaUuid = $event.value"
        ></Patcher>
      </v-col>

      <v-col
        v-if="
          data &&
            data.type !== 'text' &&
            data.type !== 'longtext' &&
            data.type !== 'date'
        "
        cols="12"
      >
        <v-card tile flat>
          <v-app-bar flat color="primary">
            <v-toolbar-title class="title white--text pl-0">
              Antworten
            </v-toolbar-title>
          </v-app-bar>

          <v-card-text>
            <table class="drag-table">
              <draggable
                v-model="data.answers"
                tag="tbody"
                ghost-class="ghost"
                :move="checkMove"
                @start="dragging = true"
                @end="handleDragEnd"
                handle=".draghandle"
              >
                <tr
                  v-for="answer in data.answers"
                  :key="answer.id"
                  class="is-draggable"
                >
                  <td class="draghandle" style="width: 32px">
                    <v-icon> mdi-drag </v-icon>
                  </td>

                  <td>{{ answer.answer }}</td>

                  <td style="width: 32px">
                    <v-btn
                      fab
                      dark
                      x-small
                      color="green"
                      elevation="0"
                      @click="openAnswer(answer)"
                      class="mx-1"
                    >
                      <v-icon dark> mdi-pencil </v-icon>
                    </v-btn>
                  </td>

                  <td style="width: 32px">
                    <v-btn
                      fab
                      dark
                      x-small
                      color="red"
                      elevation="0"
                      @click="askDelete(answer.id)"
                      class="mx-1"
                    >
                      <v-icon dark> mdi-trash-can </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </draggable>
            </table>
          </v-card-text>

          <v-card-actions>
            <v-btn
              :loading="creatingActive"
              color="green"
              class="white--text"
              @click="addItem()"
            >
              Neue Antwort
              <v-icon right dark>mdi-plus</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <template v-else>
        <v-col v-if="data">
          <v-card tile flat>
            <v-app-bar flat color="primary">
              <v-toolbar-title class="title white--text pl-0">
                Antworten
              </v-toolbar-title>
            </v-app-bar>

            <v-card-text>
              Dieser Frage-Typ ({{ data.type }}) erfordert keine festgelegten
              Antworten.
            </v-card-text>
          </v-card>
        </v-col>
      </template>
    </v-row>

    <v-dialog
      v-model="answerDialog"
      max-width="1500px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="answerDialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
          <v-toolbar-title> Antwort </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <AnswerDetails
            :data="answerData"
            :obaId="obaId"
            :setId="setId"
            :questionId="data.id"
            :key="componentKey"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <AreYouSureDialog
      title="Diese Antwort löschen?"
      text="Möchtest Du diese Antwort tatsächlich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden."
      :dialog="safeguardingDialog"
      @dialogEmit="safeguarding"
    />
  </v-container>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import draggable from "vuedraggable";
import Patcher from "@/components/ui/Patcher";
import AreYouSureDialog from "@/components/ui/AreYouSureDialog";
import AnswerDetails from "@/components/page-plugins/oba/AnswerDetails";
export default {
  name: "QuestionDetails",

  components: {
    draggable,
    Patcher,
    AnswerDetails,
    AreYouSureDialog,
  },

  data() {
    return {
      safeguardingDialog: false,
      fetchinData: false, // should be true when fetching data
      creatingActive: false,
      answerDialog: false,
      answerData: [],
      componentKey: 0,
      itemToDelete: null,
      patchRoute: `/obas/${this.obaId}/sets/${this.setId}/questions/${this.data.id}`,
      typeItems: [
        {
          name: "Texteingabe",
          value: "text",
        },
        {
          name: "Datum",
          value: "date",
        },
        {
          name: "Auswahl",
          value: "select",
        },
        {
          name: "Checkboxen",
          value: "checkbox",
        },
        {
          name: "Radio",
          value: "radio",
        },
        {
          name: "Langer Text (Textarea)",
          value: "longtext",
        },
      ],
    };
  },

  props: ["data", "obaId", "setId"],

  created: function() {
    //
  },

  methods: {
    safeguarding(value) {
      this.safeguardingDialog = false;
      if (value) {
        this.deleteItem();
      }
    },

    async deleteItem() {
      const url = `${this.patchRoute}/answers/${this.itemToDelete}`;
      let response = await this.deleteRequest(url); // await data from mixin

      if (response.status === 200) {
        let indexToDelete = null;

        this.data.answers.forEach((el, index) => {
          if (el.id === this.itemToDelete) {
            indexToDelete = index;
          }
        });

        this.data.answers.splice(indexToDelete, 1);

        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Der Datensatz wurde gelöscht";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    openAnswer(answer) {
      this.answerData = answer;
      this.componentKey += 1;
      this.answerDialog = true;
    },

    askDelete(value) {
      this.itemToDelete = value;
      this.safeguardingDialog = true;
    },

    async dragUpdate(data) {
      const url = `${this.patchRoute}/answers/sortOrder`;

      let response = await this.patchRequest(url, JSON.stringify(data), "json"); // await data from mixin

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Die Reihenfolge wurde erfolgreich geändert.";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async addItem() {
      let response = await this.postRequest(`${this.patchRoute}/answers`);

      let newItem = response.data.data;
      this.data.answers.push(newItem);

      /* start snackbar */
      this.$root.snackbarGlobal.snackbar = true;
      this.$root.snackbarGlobal.snackbarText =
        "Die Antwort wurde erfolgreich angelegt.";
      this.$root.snackbarGlobal.snackbarColor = "green";
      this.$root.snackbarGlobal.snackbarTimeout = 5000;
    },

    checkMove: function(e) {
      window.console.log("Future index: " + e.draggedContext.futureIndex);
    },

    handleMove(e) {
      /* SWAP-Hack: https://fir-vue-draggable.firebaseapp.com/swap/code */

      this.movingIndex = e.draggedContext.index;
      this.futureIndex = e.draggedContext.futureIndex;
      return false; // disable sort
    },

    handleDragEnd: function() {
      /* hier loope ich den mutierten array durch und bilde
      einen neuen array aus uuid und prio.
      Dieses Array schicke ich dann via POST an die API */

      let idAndPrio = [];
      const newOrderedArray = this.data.answers;

      let prio = 1;

      newOrderedArray.forEach((item) => {
        const set = {
          id: item.id,
          sortOrder: prio,
        };

        idAndPrio.push(set);
        ++prio;
      });

      this.dragUpdate(idAndPrio);
    },

    mutateData(value) {
      this.data[value.name] = value.value; // mutate
    },
  },

  computed: {
    //
  },

  mixins: [apiRequest, helpers],
};
</script>
