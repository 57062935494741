<template>
  <div>
    <v-container v-if="data.length > 0">
      <h2>Platzhalter</h2>
      <table class="default-table caption">
        <tbody>
          <tr v-for="item in data" :key="item.id">
            <td>
              {{ item.question }}
              <span v-if="item.type === 'text' || item.type === 'long-text'"
                >(Nutzer-Eingabe)</span
              >
            </td>
            <td>%%{{ item.feedbackPlaceholder }}%%</td>
          </tr>
        </tbody>
      </table>
    </v-container>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
export default {
  name: "PlaceholderInfo",

  components: {},

  data() {
    return {
      fetchinData: true, // should be true when fetching data
      data: [],
    };
  },

  props: ["documentUuid"],

  created: function () {
    this.getData();
  },

  methods: {
    async getData() {
      this.fetchinData = true;
      let response = await this.getRequest(
        `obas/${this.documentUuid}/questions`
      );

      this.data = response.data.data;
      this.fetchinData = false;
    },
  },

  computed: {},

  mixins: [apiRequest, helpers],
};
</script>
